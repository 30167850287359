import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

import { checkAuthentication } from '@utils/checkAuthentication';

interface IUnauthenticatedProps {
  component?: React.ReactNode;
  title?: string;
}

export const Unauthenticated = ({ component, title }: IUnauthenticatedProps) => {
  const navigate = useNavigate();

  /**
   * Check if user has authentication via api/auth/check
   * If yes, then redirect to Dashboard
   */
  useEffect(() => {
    const authenticate = async () => {
      const isAuthenticated = await checkAuthentication();

      if (isAuthenticated) {
        navigate('/');
      }
    };

    authenticate();
  }, [navigate]);

  return (
    <>
      <Helmet>
        <title>{title || 'Home'}</title>
      </Helmet>
      {component}
    </>
  );
};
