import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { AppThunkDispatch } from 'src/App';

import { Footer } from '@components/Footer';
import { Header } from '@components/Header';
import { PageLoading } from '@components/PageLoading';

import { selectProfile } from '@features/profile/profile';
import { fetchMyProfile } from '@features/profile/profile.thunks';

import { Error } from '@templates/Error';
import { Holding } from '@templates/Holding';

import { checkAuthentication } from '@utils/checkAuthentication';

interface AuthenticatedProps {
  title?: string;
  component?: React.ReactNode;
}

export const Authenticated = ({ component, title }: AuthenticatedProps) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const dispatch = useDispatch<AppThunkDispatch>();

  const navigate = useNavigate();

  const profile = useSelector(selectProfile);

  const hasCoadjuteId = profile?.value?.coadjuteParticipantId !== undefined;

  const allFetchesComplete = profile.fetchComplete;

  // Check if user has authentication via api/auth/check
  useEffect(() => {
    const authenticate = async () => {
      const isAuthenticated = await checkAuthentication();

      if (isAuthenticated) {
        dispatch(fetchMyProfile());
        setIsAuthenticated(true);
      } else {
        navigate('/signin');
      }
    };

    authenticate();
  }, []); // eslint-disable-line

  /**
   * If profile has been fetched but no moves exist then account is still being setup,
   * so show the holding page
   */
  if (profile.fetchComplete && hasCoadjuteId && profile?.value?.moves?.length === 0)
    return (
      <Holding
        title="Hold tight, we're creating your account for you"
        text="We are connecting your account to your Move Team and will have everything set up in a few moments..."
      />
    );

  // Show Error template if anything went wrong fetching the users data
  if (profile.errorOccurred)
    return <Error title="Something went wrong" text="Reloading may fix this" />;

  // Show Page loading until all data has been fetched
  if (!allFetchesComplete) return <PageLoading />;

  return (
    <>
      <Helmet>
        <title>{title || 'Home'}</title>
      </Helmet>
      <div className="flex flex-col">
        <Header loggedIn={isAuthenticated} hideNavigation />
        {component}
        <Footer isMoving={false} hideNavigation />
      </div>
    </>
  );
};
